<template>
<!-- Login 5 start -->
<div class="login-5 z-index">
    <v-container fluid class="login-box">
        <v-row>
            <v-col cols="12" sm="12" md="5" class="col-pad-0 bg-img">
                <h3 class="fs-22 mt-12">{{$t('signals.freeAccount')}}</h3>
                <a :href="SIPRegister" class="lightred--text del-underline pointer" target="black">
                  <v-btn outlined tile color="white" class="mt-12" block>{{$t("signIn.signUp")}}</v-btn>
                </a>
                <ul class="social-list clearfix">
                    <li><a href="https://twitter.com/ioBots_" class="facebook-bg del-underline" target="_blank"><v-icon small color="white">mdi-twitter</v-icon></a></li>
                    <li><a href="https://twitter.com/ioBotsPro" class="twitter-bg del-underline" target="_blank"><v-icon small color="white">mdi-twitter</v-icon></a></li>
                    <li><a href="https://t.me/iob_llc" class="linkedin-bg del-underline" target="_blank"><v-icon small color="white">mdi-telegram</v-icon></a></li>
                </ul>
            </v-col>
            <v-col cols="12" sm="12" md="7" class="col-pad-0" dark>
                <div class="login-inner-form" v-show="!isShow2FA">
                    <div class="details grey--text">
                      <h1 class="fs-28 mt-3">{{$t("signIn.signIn")}}</h1>
                      <h3 class="fs-16 mt-3 hover-gradient">{{$t("signIn.signIn")}} {{$t("signIn.or")}} <a :href="SIPRegister" class="lightred--text del-underline" target="black">{{$t("signIn.signUp")}}</a> {{$t("signIn.with")}}  <a :href="SIP" class="lightred--text del-underline" target="black">{{$t("signIn.sip")}} </a> {{$t("signIn.account")}}</h3>
                      <v-form ref="userForm" v-model="userFormValid" class="mb-4 pb-2 mt-5 grey--text">
                      <v-text-field v-model="userForm.username" :rules="usernameRules"  :placeholder="$t('signIn.userName')" required @keyup.enter="signIn" clearable></v-text-field>
                      <v-text-field v-model="userForm.password" :rules="passwordRules"  :placeholder="$t('signIn.passWord')" :append-icon="togglePasswordVisibility ? 'mdi-eye-off' : 'mdi-eye'" :type="togglePasswordVisibility ? 'password' : 'text'" @click:append="togglePasswordVisibility = !togglePasswordVisibility" required  @keyup.enter="signIn" clearable></v-text-field>
                      <!-- <v-text-field v-model="userForm.otp"  :placeholder="$t('signIn.enabledMsg')" type="text" @keyup.enter="signIn" clearable></v-text-field> -->
                      <div class="float-right mb-5 hover-gradient">
                        <a :href="SIPPassword" class="lightred--text del-underline fs-16" target="black">{{$t("signIn.forgotPassword")}}</a>
                      </div>
                      <v-btn tile block depressed :loading="signInLoading" :disabled="!userFormValid" class="button--gradient-orange black--text mt-1 mb-0 mr-0 mt-5 submit" @click="signIn">{{$t("signIn.signIn")}}</v-btn>
                      <v-checkbox v-model="userForm.agreeTerms" class="mt-5" color="lightred" :rules="[v => !!v || $t('signIn.requiredMessage.agree')]" required>
                        <v-card-text slot="label" class="mt-2 fs-16">
                          {{$t("signIn.agree")}}<span class="lightred--text pointer" @click.stop="isShowTermsDialog = !isShowTermsDialog"> {{$t("signIn.termOfConditions")}} </span>{{$t("signIn.and")}} <a class="lightred--text del-underline pointer" :href="SIP + '/#/about/privacy'" target="_blank"> {{$t("signIn.privacyPolicy")}} </a>
                        </v-card-text>
                      </v-checkbox>
                    </v-form>
                    </div>
                </div>
                <div class="login-inner-form" v-show="isShow2FA">
                  <div class="details grey--text">
                    <h1 class="fs-28 mt-3">{{$t("signIn.googleAuthenticator")}}</h1>
                    <h3 class="fs-16 mt-3">{{$t("signIn.googleAuthenticatorMessage")}}</h3>
                    <v-form ref="userForm" v-model="userFormValid" class="mb-4 pb-0 mt-5 grey--text" @submit.native.prevent>
                      <v-text-field v-model="userForm.otp" :placeholder="$t('signIn.twoFactorAuthentication')" type="text" @keyup.enter.stop="signIn" clearable class="mt-16"></v-text-field>
                      <v-btn light tile block depressed :loading="signInLoading" :disabled="!userFormValid" class="button--gradient-orange mt-1 mb-0 mr-0 mt-5 pb-0 submit" @click="signIn">{{$t("signIn.signIn")}}</v-btn>
                    </v-form>
                  </div>
                  <div class="details float-left py-0">
                    <span class="pointer" @click="isShow2FA = false">
                      <v-icon size="20" color="grey">mdi-arrow-left</v-icon><span class="grey--text">Back</span>
                    </span>
                  </div>
                </div>
            </v-col>
        </v-row>
        <v-layout>
            <v-dialog v-model="isShowTermsDialog" persistent width="720" no-click-animation>
              <v-card>
                <v-card-title class="grey lighten-2">
                    <span class="black--text">{{$t("signIn.termOfConditions")}} </span>
                    <v-spacer></v-spacer>
                    <v-btn light icon  @click="isShowTermsDialog = false;userForm.agreeTerms = true">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                    <app-terms></app-terms>
                </v-card>
          </v-dialog>
        </v-layout>
     </v-container>
</div>
<!-- Login 5 end -->
</template>
<style>
</style>
<script>
  import Vue from 'vue'
  import config from '@/config.js'
  import { mapGetters } from "vuex";
    export default {
          data () {
            return {
              togglePasswordVisibility: true,
              userFormValid: true,
              isShowTermsDialog: false,
              terms: 'Terms and Conditions',
              userForm: {
                username: null,
                password: null,
                otp: null,
                agreeTerms: true
              },
              usernameRules: [
                v => !!v || (this.$t("signIn.requiredMessage.userName"))
              ],
              passwordRules: [
                v => !!v || (this.$t("signIn.requiredMessage.passWord"))
              ],
              SIP: config.sip,
              SIPPassword: config.sip + '/#/password',
              SIPRegister: config.sip + '/#/register',
              signInLoading: false,
              // 是否显示2FA
              isShow2FA: false
            }
          },
          computed:{
            ...mapGetters(['user', 'ip'])
          },
          created(){
            if (this.user.token) {
              this.$router.push({ name: 'home' })
            }
            // 读取用户名和密码
            this.userForm.username = localStorage.getItem('iobots-username');
            this.userForm.password = localStorage.getItem('iobots-password');
          },
          methods: {
            signIn(){
              if (this.$refs.userForm.validate()) {
                this.signInLoading = true;
                localStorage.setItem('iobots-username', this.userForm.username);
                localStorage.setItem('iobots-password', this.userForm.password);
                let api = config.baseUrl + '/user/public/login';
                let sha256 = require("js-sha256").sha256;
                let password = sha256(this.userForm.password);
                let param = {
                    username: this.userForm.username,
                    password: password,
                    otp: this.userForm.otp,
                    ip: this.ip
                };
                this.$http.post(api, param).then(response => {
                  let data = response.data;
                  this.signInLoading = false;
                  if (data.code == 200) {
                    if( !localStorage.getItem('iobots-time')){
                      this.$store.dispatch('timeHandler','localTime')
                    }
                    // 登录成功，存储用户信息
                    this.$store.dispatch('userHandler', data.result);
                    // localStorage.setItem("iobots-token", data.result.token);
                    // localStorage.setItem("iobots-image", data.result.avatar);
                    console.log(config.domainName)
                    window.location.href = config.domainName;
                  } else if(data.code == 22201){
                    // 用户名/密码验证通过，需要输入2FA开始认证
                    if (!this.isShow2FA) {
                      this.isShow2FA = true;
                    } else {
                      this.userForm.otp = null;
                      this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                  } else {
                    // 用户名或密码错误
                    this.userForm.otp = null;
                    this.$store.dispatch('snackbarMessageHandler', data.message);
                  }
                }, error => {
                  this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                  this.signInLoading = false;
                });
              } else {
                this.$store.dispatch('snackbarMessageHandler', (this.$t("signIn.snackbarMessageHandler")));
              }
            },
          },
    }
</script>
